import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { apiRequest } from '../config/api.js';

import './css/Home.css';
import './css/Features.css';
import LiteParallelTextHistory from './LiteParallelTextHistory';
  
const Home = ({ 
    showVideo, 
    handleTryApp, 
    handleRequestLanguage,
    renderLanguageColumns,
    renderClassicBooks,
    featureCardContents,
    featureCardsLoading,
  }) => {
    const { t, i18n } = useTranslation();
    const nativeLanguage = i18n.language;

    // Add function to generate random hover indices
    const generateRandomHoverIndices = (pageContent) => {
        if (!pageContent?.wordAssociations?.association?.word_maps?.[0]) {
            return null;
        }

        // Get the first sentence's word map
        const firstSentenceMap = pageContent.wordAssociations.association.word_maps[0];
        
        // Get all source words that have target mappings
        const validSourceIndices = Object.entries(firstSentenceMap.source_to_target)
            .filter(([_, targets]) => targets && targets.length > 0)
            .map(([sourceIdx]) => sourceIdx);

        if (validSourceIndices.length === 0) {
            return null;
        }

        // Randomly select a source word
        const randomSourceIdx = validSourceIndices[Math.floor(Math.random() * validSourceIndices.length)];
        
        // Get its target mappings
        const targetIndices = firstSentenceMap.source_to_target[randomSourceIdx] || [];

        if (targetIndices.length === 0) {
            return null;
        }

        // Return the hover indices structure
        return {
            sentenceIndex: 0,
            wordIndices: {
                source: [randomSourceIdx],
                target: targetIndices
            }
        };
    };

    // Helper function to render loading state
    const renderLoadingCard = () => (
      <div className="feature-loading">
        <div className="loading-spinner"></div>
      </div>
    );

    return (
        <main className="main-content">
            <h1 id="home-title">ABAL: All Books, All Languages</h1>

            <p className="subtitle">
                {t('home.subtitle')}
            </p>
            <div className="cta-buttons">
                <button className="cta-button" onClick={handleTryApp}>
                <span>{t('nav.openApp')}</span>
                </button>
            </div>

            <div className="features-container">
                <div className="feature-card">
                <div className="feature-left">
                    <h3>{t('home.languages.title')}</h3>
                    <p>{t('home.languages.description')}</p>
                </div>
                <div className="feature-right">
                    {renderLanguageColumns()}
                </div>
                </div>
            </div>

            <div className="features-container">
                <div className="feature-card">
                    <div className="feature-left">
                        <h3>{t('home.categories.title')}</h3>
                        <p>{t('home.categories.description')}</p>
                    </div>
                    <div className="feature-right">
                        <div className="categories-preview">
                            {Object.entries({
                                beginner: ['introductions', 'weather', 'daily_routines'],
                                intermediate: ['travel', 'movies', 'cooking'],
                                advanced: ['business', 'environmental', 'medical'],
                                fluent: ['science', 'economic_policy', 'ai_ethics']
                            }).map(([level, topics]) => (
                                <div key={level} className="difficulty-group">
                                    <h4>{t(`difficulty_levels.${level}`)}</h4>
                                    <ul>
                                        {topics.map(topic => (
                                            <li key={topic}>
                                                {t(`story_categories.topics.${level}.${topic}`)}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="features-container">
                <div className="feature-card">
                <div className="feature-left">
                    <h3>{t('home.classics.title')}</h3>
                    <p>{t('home.classics.description')}</p>
                    <button id="explore-library" disabled>
                        {t('home.classics.comingSoon')}
                    </button>
                </div>
                <div className="feature-right">
                    {renderClassicBooks()}
                </div>
                </div>
            </div>

            <h1>{t('home.features.title')}</h1>
            <p className="subtitle">
                {t('home.features.subtitle')}
            </p>

            {/* Word Associations Feature */}
            <div className="features-container">
                <div className="feature-card">
                    <div className="feature-left">
                        <h3>{t('home.features.wordAssociations.title')}</h3>
                        <p>{t('home.features.wordAssociations.description')}</p>
                    </div>
                    <div className="feature-right">
                        {featureCardsLoading ? renderLoadingCard() : (
                            <LiteParallelTextHistory 
                                dyslexicFontEnabled={false}
                                textAlignment="center" 
                                pageContent={featureCardContents.wordAssociations}
                                showNativeLanguage={true}
                                showPhonetics={false}
                                showFurigana={false}
                                nativeLanguageDirection={'ltr'}
                                learningLanguageDirection={'ltr'}
                                nativeLanguage={nativeLanguage}
                                learningLanguage={featureCardContents.wordAssociations?.learning_language}
                                overrideHoverIndices={generateRandomHoverIndices(featureCardContents)}
                            />
                        )}
                    </div>
                </div>
            </div>

            {/* Furigana Feature */}
            <div className="features-container">
                <div className="feature-card">
                    <div className="feature-left">
                        <h3>{t('home.features.readingAids.title')}</h3>
                        <p>{t('home.features.readingAids.description')}</p>
                    </div>
                    <div className="feature-right">
                        {featureCardsLoading ? renderLoadingCard() : (
                            <LiteParallelTextHistory 
                                dyslexicFontEnabled={false}
                                textAlignment="center" 
                                pageContent={featureCardContents.furigana}
                                pageFurigana={featureCardContents.furigana?.furigana}
                                showNativeLanguage={false}
                                showPhonetics={false}
                                showFurigana={true}
                                nativeLanguageDirection={'ltr'}
                                learningLanguageDirection={'ltr'}
                                nativeLanguage={nativeLanguage}
                                learningLanguage={featureCardContents.furigana?.learning_language}
                                disableHover={true}
                            />
                        )}
                    </div>
                </div>
            </div>

            {/* Phonetics Feature */}
            <div className="features-container">
                <div className="feature-card">
                    <div className="feature-left">
                        <h3>{t('home.features.phonetics.title')}</h3>
                        <p>{t('home.features.phonetics.description')}</p>
                    </div>
                    <div className="feature-right">
                        {featureCardsLoading ? renderLoadingCard() : (
                            <LiteParallelTextHistory 
                                dyslexicFontEnabled={false}
                                textAlignment="center" 
                                pageContent={featureCardContents.phonetics}
                                pagePronunciation={featureCardContents.phonetics?.pronunciation}
                                showNativeLanguage={false}
                                showPhonetics={true}
                                showFurigana={false}
                                nativeLanguageDirection={'ltr'}
                                learningLanguageDirection={'ltr'}
                                nativeLanguage={nativeLanguage}
                                learningLanguage={featureCardContents.phonetics?.learning_language}
                                disableHover={true}
                            />
                        )}
                    </div>
                </div>
            </div>

            {/* Hard Mode Feature */}
            <div className="features-container">
                <div className="feature-card">
                    <div className="feature-left">
                        <h3>{t('home.features.interlacedText.title')}</h3>
                        <p>{t('home.features.interlacedText.description')}</p>
                    </div>
                    <div className="feature-right">
                        {featureCardsLoading ? renderLoadingCard() : (
                            <LiteParallelTextHistory 
                                dyslexicFontEnabled={false}
                                textAlignment="center" 
                                pageContent={featureCardContents.hardMode}
                                showNativeLanguage={false}
                                showPhonetics={false}
                                showFurigana={false}
                                nativeLanguageDirection={'ltr'}
                                learningLanguageDirection={'ltr'}
                                nativeLanguage={nativeLanguage}
                                learningLanguage={featureCardContents.hardMode?.learning_language}
                                disableHover={true}
                            />
                        )}
                    </div>
                </div>
            </div>

            {/* Dyslexic Font Feature */}
            <div className="features-container">
                <div className="feature-card">
                    <div className="feature-left">
                        <h3>{t('home.features.dyslexia.title')}</h3>
                        <p>{t('home.features.dyslexia.description')}</p>
                    </div>
                    <div className="feature-right">
                        {featureCardsLoading ? renderLoadingCard() : (
                            <LiteParallelTextHistory 
                                dyslexicFontEnabled={true}
                                textAlignment="center" 
                                pageContent={featureCardContents.dyslexic}
                                showNativeLanguage={false}
                                showPhonetics={false}
                                showFurigana={false}
                                nativeLanguageDirection={'ltr'}
                                learningLanguageDirection={'ltr'}
                                nativeLanguage={nativeLanguage}
                                learningLanguage={featureCardContents.dyslexic?.learning_language}
                                disableHover={true}
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className="try-abal-section" onClick={handleTryApp}>
                <h2>{t('home.cta.title', { appName: 'ABAL' })}</h2>
                <button id="try-for-free">{t('nav.openApp')}</button>
            </div>

        </main>
    );
  };
  
  export default Home;
